export const homeObjFive = {
  id: "projects",
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  sectionDescription: "A Portfolio Selection",
  lightText: false,
  lightTextDesc: false,
  headline: "Projects",
  description: "Web CRUD app for scheduling a neighbourhood tool rental built with Postgres, Express, React, Node.",
  description2: "Ontario Art Education Association website  built WordPress CMS with additional functional elements, customized JS, jQuery and PHP. Designed and built under the direction of Get Media Done.",
  description3: "E-commerce store built on the Shopify platform with custom features injected using Liquid templating language.",
  description4: "Custom business storefront for a boutique chocolatier, built on WordPress CMS with Elementor and jQuery.",
  description5: "Buisness website for a movement and meditation instructor built on Wordpress using GeneratePress, in collaboration with Janine Stoll Media.",
  description6: "Redesigned organizational webplatform for the Ontario Association of Geography and Environmental Educators. Designed and built under the direction of Get Media Done.",
  description7: "Single page site for professional speech pathologist, designed and built in collaboration with Janine Stoll Media.",
  description8: "WordPress website for Radiohead tribute band built using customized GeneratePress theme.",
  description9: "Redisign of existing artist site built on SquareSpace.",
  buttonLabel: "",
  img: require("../../images/ToolSwap.jpg"),
  img2: require("../../images/OAEA.png"),
  img3: require("../../images/StudioPothos.jpeg"),
  img4: require("../../images/Milon-Backt.jpg"),
  img5: require("../../images/Amy-Dhindsa.jpg"),
  img6: require("../../images/OAGEE-Homepage.jpg"),
  img7: require("../../images/CommunicationAndBeyond.jpg"),
  img8: require("../../images/Idioteque-Website-Design.jpg"),
  img9: require("../../images/GillianMoranz.jpg"),
  start: "",
  alt: "Image link to site",
  viewButton: false,
};